<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="close">
            <ion-icon :ios="icons.closeOutline" :md="icons.closeSharp"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" padding>
      <!-- form ข้อมูลบัญชี -->
      <ion-list class="form_account" lines="full">
        <ion-list-header lines="full">
          <ion-label>{{ title }}</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label>Name</ion-label>
          <ion-input class="ion-text-right" v-model.trim="account_name"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label>Description</ion-label>
          <ion-input class="ion-text-right" v-model.trim="account_description"></ion-input>
        </ion-item>

        <template v-if="isAssetsMode">
          <template v-if="createMode">
            <ion-item>
              <ion-label>กำหนดวันเริ่มต้น</ion-label>
              <ion-toggle v-model="begin"></ion-toggle>
            </ion-item>
            <template v-if="begin">
              <ion-item>
                <ion-label>เริ่มต้นวันที่</ion-label>
                <ion-datetime
                  display-format="YYYY-MM-DD"
                  min="1997"
                  v-model="begin_date"
                ></ion-datetime>
              </ion-item>
              <ion-item>
                <ion-label>เงินเริ่มต้น</ion-label>
                <ion-input class="ion-text-right" v-model="begin_amount"></ion-input>
              </ion-item>
            </template>
          </template>
        </template>

        <template v-if="isCategoriesMode && rootCategories.length > 0">
          <ion-item>
            <ion-label>เป็นหมวดหมู่ย่อยของ</ion-label>
            <ion-select v-model="parent_id" interface="popover">
              <ion-select-option v-for="c in optionRootCategories" :key="c.account_id" :value="c.account_id">{{ c.account_name }}</ion-select-option>
            </ion-select>
          </ion-item>
        </template>

        <section>
          <ion-button expand="full" @click="save">{{ saveButtonLabel }}</ion-button>
        </section>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  // module
  modalController,
  // components
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonToolbar,
} from "@ionic/vue";
import { closeOutline, closeSharp } from "ionicons/icons";
import { mapGetters } from "vuex";
import MixinsIonicAlert from "./MixinsIonicAlert.vue";

export default {
  name: "ModalAccountEditor",
  mixins: [MixinsIonicAlert],
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonToggle,
    IonToolbar,

  },
  props: {
    account: { type: Object },
    isCategoriesMode: { required: true, type: Boolean },
    isAssetsMode: { required: true, type: Boolean },
    kind: { required: true, type: String },
  },
  data() {
    return {
      account_name: this.account ? this.account.account_name : "",
      account_description: this.account ? this.account.account_description : "",
      begin: false,
      begin_date: new Date().toISOString(),
      begin_amount: "0.00",
      parent_id: this.account ? this.account._hierarchy_parent_id : 0,
    };
  },
  computed: {
    ...mapGetters({
      currentAssets: "account/currentSaveAssets",
      rootCategories: "account/rootCategories",
    }),
    icons() {
      return {
        closeOutline,
        closeSharp,
      };
    },
    createMode() {
      return !this.account;
    },
    kindName() {
      return this.isAssetsMode ? "บัญชี" : "หมวดหมู่";
    },
    title() {
      return (this.createMode ? "เพิ่ม" : "แก้ไข") + this.kindName;
    },
    saveButtonLabel() {
      return this.createMode ? "สร้าง" : "บันทึก";
    },
    optionRootCategories() {
      const options = [...this.rootCategories];
      options.sort((a, b) =>
        b.account_name < a.account_name ? 1 : -1
      );
      return [{ account_name: "ไม่เป็นหมวดหมู่ย่อย", account_id: 0 }, ...options ];
    },
  },
  methods: {
    close() {
      modalController.dismiss();
    },
    save() {
      if (this.account_name === "") {
        this.alert({ header: "Name is required" });
        return;
      }

      if (this.createMode) {
        for (const asset of this.currentAssets) {
          if (asset.account_name === this.account_name) {
            this.alert({ header: "Account name must be unique" });
            return;
          }
        }
      }

      if (this.begin) {
        if (!this.begin_date.match(/^\d{4}-\d{2}-\d{2}/)) {
          this.alert({ header: "begin date - invalid format" });
          return;
        }
        if (!this.begin_amount.match(/^\d+(\.\d{2})?$/)) {
          this.alert({ header: "begin amount - invalid decimal format" });
          return;
        }
      }

      const payload = {
        kind: this.kind, // assets or categories
        account_name: this.account_name,
        account_description: this.account_description,
      };
      if (this.createMode && this.isAssetsMode) {
        payload.begin = this.begin;
        payload.begin_date = this.begin_date.toString().substr(0, 10);
        payload.begin_amount = this.begin_amount;
      }

      if (!this.createMode) {
        payload.account_id = this.account.account_id;
      }

      if (this.isCategoriesMode) {
        payload.parent_id = this.parent_id;
      }

      modalController.dismiss(payload);
    },
  },
};
</script>

<style scoped>
ion-list.form_account ion-item ion-label {
  --color: var(--ion-color-primary);
}
section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}
section:not(.full-width), .full-width > header {
  padding: 0 10px;
}
</style>
