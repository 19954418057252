<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-button :router-link="$store.state.cameFromFullPath && !['/app/assets', '/app/categories'].includes($store.state.cameFromFullPath) ? { path: $store.state.cameFromFullPath } : { name: 'settings' }" router-direction="back">
            <ion-icon :ios="icons.backOutline" :md="icons.backSharp"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ title }}
          <ion-icon
            :ios="icons.infoOutline"
            :md="icons.infoSharp"
            @click="showHelp"
          ></ion-icon>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="addNewAssetModal">
            <ion-icon :icon="icons.addOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar v-model="filter"></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" padding>
      <!-- refresher -->
      <ion-refresher
        slot="fixed"
        @ionRefresh="reload($event)"
        pull-min="50"
        snapback-duration="1000ms"
      >
        <ion-refresher-content
          :pulling-icon="icons.chevronDownCircleOutline"
          pulling-text="Pull to refresh"
          refreshing-spinner="circles"
          refreshing-text="Getting..."
        >
        </ion-refresher-content>
      </ion-refresher>

      <ion-list>
        <ion-item-sliding
          v-for="a in displayingListAccounts"
          :key="a.account_id"
          :ref="
            (el) => {
              slidingRefs[a.account_id] = el;
            }
          "
        >
          <!-- ชื่อบัญชี และ balance -->
          <ion-item
            lines="full"
            :router-link="{ name: 'tx', params: { id: a.account_id } }"
            router-direction="forward"
            button
            details="false"
          >
            <ion-icon
              slot="start"
              color="medium"
              :ios="icons.transactionOutline"
              :md="icons.transactionSharp"
            ></ion-icon>

            <ion-label>
              <ion-text v-if="isCategoriesMode" color="medium">{{ hashedCategories[a.account_id].parentString }}</ion-text>
              <ion-text>{{ a.account_name }}</ion-text>
              <p v-if="a.account_description">
                {{ a.account_description }}
              </p>
            </ion-label>
            <ion-label slot="end" v-if="isAssetsMode">
              <ion-text :color="a.account_balance>=0 ? 'primary' : 'danger'">{{ number_format(a.account_balance) }}</ion-text>
            </ion-label>
          </ion-item>

          <ion-item-options side="end">
            <ion-item-option color="danger" @click="confirmDelete(a)" v-if="isAssetsMode">
              <ion-icon :ios="icons.deleteOutline" :md="icons.deleteSharp"></ion-icon>
              Del
            </ion-item-option>
            <ion-item-option color="primary" @click="edit(a)">
              <ion-icon :ios="icons.editOutline" :md="icons.editSharp"></ion-icon>
              Edit
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-list>

      <!-- fab placed in the center of the content with a list on each side -->
      <ion-fab v-if="isAssetsMode" vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button color="light">
          <ion-icon :icon="icons.swapVerticalOutline"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button v-if="isAssetsMode" @click="setSortBy('amount')" :color="sortBy==='amount'?'medium':'light'"><ion-icon :icon="icons.cashOutline"></ion-icon></ion-fab-button>
          <ion-fab-button @click="setSortBy('name')" :color="sortBy==='name'?'medium':'light'"><ion-icon :icon="icons.textOutline"></ion-icon></ion-fab-button>
        </ion-fab-list>
      </ion-fab>
    </ion-content>

    <ion-footer></ion-footer>
  </ion-page>
</template>

<script>
import {
  // controller
  modalController,
  // components
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonText,
  IonTitle,
  IonToolbar,
  IonMenuButton,
} from "@ionic/vue";
import {
  addOutline,
  cashOutline,
  chevronBackOutline,
  chevronBackSharp,
  chevronDownCircleOutline,
  fileTrayFullOutline,
  fileTrayFullSharp,
  flameOutline,
  informationCircleOutline,
  informationCircleSharp,
  pencilOutline,
  pencilSharp,
  swapVerticalOutline,
  textOutline,
  trashOutline,
  trashSharp,
} from "ionicons/icons";
import { mapActions, mapGetters, mapState } from "vuex";
import { numberFormatComma, extractAxiosErrorResponse } from "../../helper.js";
import ModalAccountEditor from "./ModalAccountEditor.vue";
import MixinsIonicAlert from "./MixinsIonicAlert.vue";

export default {
  name: "Assets",
  mixins: [MixinsIonicAlert],
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonFabList,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    IonText,
    IonTitle,
    IonToolbar,
    IonMenuButton,
  },
  data() {
    return {
      slidingRefs: {},
      filter: "",
      sortBy: "name",
    };
  },
  computed: {
    ...mapState({
      transaction_changed: (state) => state.account.transaction_changed,
    }),
    ...mapGetters({
      rawCurrentSaveAssets: "account/currentSaveAssets",
      rawCurrentSaveCategories: "account/currentSaveCategories",
      hashedCategories: "account/hashedCategories",
    }),
    title() {
      if (this.isAssetsMode) return "บัญชี";
      else if (this.isCategoriesMode) return "หมวดหมู่";
      else return "";
    },
    icons() {
      return {
        addOutline,
        chevronDownCircleOutline,
        swapVerticalOutline,
        flameOutline,
        cashOutline,
        textOutline,
        deleteOutline: trashOutline,
        deleteSharp: trashSharp,
        editOutline: pencilOutline,
        editSharp: pencilSharp,
        backOutline: chevronBackOutline,
        backSharp: chevronBackSharp,
        infoOutline: informationCircleOutline,
        infoSharp: informationCircleSharp,
        transactionOutline: fileTrayFullOutline,
        transactionSharp: fileTrayFullSharp,
      };
    },
    isCategoriesMode() {
      return this.mode === "categories";
    },
    isAssetsMode() {
      return this.mode === "assets";
    },
    displayingListAccounts() {
      const filter = this.filter.toLowerCase();

      let raw;
      if (this.isCategoriesMode)
        raw = this.rawCurrentSaveCategories;
      else if (this.isAssetsMode)
        raw = this.rawCurrentSaveAssets;
      else
        raw = [];

      return raw.filter(a => filter === "" || (
        a.account_name.toLowerCase().indexOf(filter) !== -1
        || (
          this.isCategoriesMode
          && this.hashedCategories[a.account_id].parentString.toLowerCase().indexOf(filter) !== -1
        )
      ) )
        .sort((a, b) => {
          if (this.sortBy === "hot") {
            return (b.cache_count_deposit + b.cache_count_withdraw + b.cache_count_transfer_credit + b.cache_count_transfer_debit)
              - (a.cache_count_deposit + a.cache_count_withdraw + a.cache_count_transfer_credit + a.cache_count_transfer_debit);
          }
          else if (this.sortBy === "name") {
            if (this.isCategoriesMode) {
              const aName = `${this.hashedCategories[a.account_id].parentString}${a.account_name}`;
              const bName = `${this.hashedCategories[b.account_id].parentString}${b.account_name}`;
              return bName < aName ? 1 : -1;
            }
            else
              return b.account_name < a.account_name ? 1 : -1;
          }
          else if (this.sortBy === "amount") {
            return b.account_balance - a.account_balance;
          }
        });
    },
  },
  methods: {
    ...mapActions({
      loadAccounts: "account/loadAccounts",
      editAccount: "account/editAccount",
      createAccount: "account/createAccount",
      deleteAccount: "account/deleteAccount",
    }),
    number_format(number) {
      return numberFormatComma(number);
    },
    async reload(event) {
      try {
        await this.loadAccounts();
      } finally {
        if (event && event.target && typeof event.target.complete === "function") {
          event.target.complete();
        }
      }
    },
    async addNewAssetModal() {
      await this.openAccountEditor();
    },
    async confirmDelete(account) {
      await this.alert({
        header: "Confirm?",
        message: "จะลบได้เฉพาะบัญชีที่ไม่มีรายการเท่านั้น",
        buttons: [
          {
            text: "Cancel",
            role: "cancel"
          },
          {
            text: "Delete",
            handler: async () => {
              try {
                await this.deleteAccount(account);
              } catch (e) {
                if (e.response && e.response.data && e.response.data.message) {
                  await this.alert({ header: "ผิดพลาด", message: e.response.data.message });
                }
              }
            },
          }
        ],
      });
    },
    async edit(account) {
      await this.openAccountEditor(account);
    },
    async openAccountEditor(account) {
      const modal = await modalController.create({
        component: ModalAccountEditor,
        componentProps: {
          account,
          isCategoriesMode: this.isCategoriesMode,
          isAssetsMode: this.isAssetsMode,
          kind: this.mode,
        },
        swipeToClose: false,
      });
      modal.onWillDismiss().then(async (receiving) => {
        if (typeof receiving.data === "object" && receiving.data !== null) {
          const payload = receiving.data;

          try {
            if (payload.account_id) {
              await this.editAccount(payload);
            } else {
              await this.createAccount(payload);
            }
          } catch (e) {
            const extracted = extractAxiosErrorResponse(e);
            if (extracted) {
              await this.alert(extracted);
            }
          }

          if (
            payload.account_id &&
            this.slidingRefs[payload.account_id] &&
            this.slidingRefs[payload.account_id].$el
          ) {
            this.slidingRefs[payload.account_id].$el.close();
          }
        }
      });
      return modal.present();
    },
    setSortBy(by) {
      this.sortBy = by;
    },
    async showHelp() {
      await this.alert({
        header: "วิธีใช้",
        message: `ปัด${this.title}ไปทางซ้าย เพื่อแสดงปุ่มแก้ไข`,
      });
    },
  },
  beforeUpdate() {
    this.slidingRefs = {};
  },
  async mounted() {
    if (this.transaction_changed) {
      await this.loadAccounts();
    }
  },
};
</script>

<style scoped></style>
